import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TimeParserService } from 'src/app/services/time-parser/time-parser.service';

@Component({
  selector: 'app-datetime-selector',
  templateUrl: './datetime-selector.component.html',
  styleUrls: ['./datetime-selector.component.scss'],
})
export class DatetimeSelectorComponent implements OnInit {

  @Input() minimumDate: Date;
  @Input() inputDate: Date;
  
  selectedDate: Date;

  selectorOptionsHs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  selectorOptionsMinutes = [0, 30];
  selectorOptionsTime = ["AM", "PM"];

  constructor(
    private modalController: ModalController,
    private timeParserService: TimeParserService
  ) { }

  ngOnInit() {
    console.log("app-datetime-selector:ngOnInit selectedDate => ", this.selectedDate, " minimumDate => ", this.minimumDate);

    this.selectedDate = this.inputDate || new Date();
    this.updateState();
  }

  prevDay() {
    this.calcTime(-1);
  }

  nextDay() {
    this.calcTime(1);
  }

  calcTime(dayAdjustment = 0) {
    console.log("calcTime:dayAdjustment => ", dayAdjustment);

    let newDate = new Date(this.selectedDate);

    newDate.setDate(newDate.getDate() + dayAdjustment);
    if(newDate < this.minimumDate) {
      return;
    }

    this.selectedDate = newDate;
    console.log("calcTime:selectedDate => ", this.selectedDate);
    this.updateState();
  }

  setDatetime(func: (date: Date) => Date) {
    this.selectedDate = func(new Date(this.selectedDate));
    this.updateState();
  }

  activeHs : number;
  activeMins : number;
  activeTime : number;

  setHours(index) {
    this.setDatetime((date) => {
      let isPm = date.getHours() > 12;
      let hours = this.selectorOptionsHs[index];
      date.setHours(isPm ? hours + 12 : hours);
      return date;
    });
  }

  setMins(index) {
    this.setDatetime((date) => {
      let minutes = this.selectorOptionsMinutes[index];
      date.setMinutes(minutes);
      return date;
    });
  }

  setTime(index) {
    this.setDatetime((date) => {
      let time = this.selectorOptionsTime[index];
      date.setHours(date.getHours() % 12 + (time === 'AM' ? 0 : 12));
      return date;
    });
  }

  isHourButtonEnabled(index) {
    let date = new Date(this.selectedDate);
    let isPm = date.getHours() > 12;
    let hours = this.selectorOptionsHs[index];
    date.setHours(isPm ? hours + 12 : hours);

    return date <= this.minimumDate;
  }

  isMinuteButtonEnabled(index) {
    let date = new Date(this.selectedDate);
    let minutes = this.selectorOptionsMinutes[index];
    date.setMinutes(minutes);

    return date <= this.minimumDate;
  }

  isTimeButtonEnabled(index) {
    let date = new Date(this.selectedDate);
    let time = this.selectorOptionsTime[index];
    date.setHours(date.getHours() % 12 + (time === 'AM' ? 0 : 12));
    return date <= this.minimumDate;
  }

  updateState() {
    console.log("updateState:selectedDate => ", this.selectedDate);
    let hours = this.selectedDate.getHours() > 12 ? this.selectedDate.getHours() - 12 : this.selectedDate.getHours();
    if(hours === 0) {
      hours = 12;
    }
    this.activeHs = this.selectorOptionsHs.findIndex(h => h === hours);
    this.activeMins = this.selectorOptionsMinutes.findIndex(m => m === this.selectedDate.getMinutes());
    if(this.activeMins === -1) {
      this.activeMins = 0;
    }
    this.activeTime = this.selectedDate.getHours() < 12 ? 0 : 1;
  }

  clearHours() {
    this.selectedDate = new Date(this.inputDate);
    this.updateState();
  }

  confirmTime() {
    this.modalController.dismiss(this.selectedDate);
  }

  closeModal() {
    this.modalController.dismiss(null);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { Command } from 'src/app/models/Command';
import { BaseFile, SimpleFile, SimpleFolder } from 'src/app/models/interfaces/SimpleFile';
import { TrackedFolder } from 'src/app/models/TrackedFolder';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { DataLayerService } from '../data-layer/data-layer.service';
import { IdbService } from '../indexDB/idb.service';
import { UpdateQueueService } from '../update-queue/update-queue.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  apiServer: string = environment.apiServer;
  _sessionID: string = '';
  get sessionID(){
    if(this._sessionID === null){
      this._sessionID = this.auth.getLocalSessionID()
    } 
    return this._sessionID;
  }
  set sessionID(val){
    this._sessionID=val;
  }
  deviceSerial: string = '';
  deviceFoldersBySerial = [];

  private jobsDataSubject = new BehaviorSubject<any[]>([]);
  jobsData$: Observable<any[]> = this.jobsDataSubject.asObservable();

  processAttempts = 0;

  constructor(private idb: IdbService,
    private ds: DataLayerService, private http: HttpClient,
    private uQueue: UpdateQueueService, private auth: AuthService,
    private toastController: ToastController
  ) {
    
    this.sessionID = auth.currentSessionID.value;
    if(this.sessionID === null){
      this.sessionID = auth.getLocalSessionID()
    }
    
  }

  async refreshFolders() {
    //this.deviceSerial = this.auth.currentDeviceSerial.value;
    this.deviceSerial = await localStorage.getItem('deviceSerial');
    this.sessionID = await localStorage.getItem('currentSessionID')
    let cmd = new Command();
    cmd.procedure = 'cmdGetDeviceFoldersBySerial';
    cmd.addParameter('DeviceSerial', this.deviceSerial);

    // Retrieve folders from the server
    this.deviceFoldersBySerial = await new Promise((resolve, reject) => {
        this.ds.command(cmd).subscribe({
            next: (folders) => {
                resolve(folders);
            },
            error: (error) => {
                reject(error);
            }
        });
    });

    console.log("deviceFoldersBySerial => ", this.deviceFoldersBySerial)
}

async processFolders(){
  console.log('processFolders... ', this.deviceFoldersBySerial);
  this.processAttempts += 1;
  if(this.processAttempts === 1 ||  this.deviceFoldersBySerial.length === 0){
    await this.refreshFolders();
  }
  const processedFolderData: any = [];
  const uniqueFolderIds = new Set();
  const filteredFolders = this.deviceFoldersBySerial.filter(folder => {
    const isUnique = !uniqueFolderIds.has(folder.folderID);
    uniqueFolderIds.add(folder.folderID);
    return isUnique;
  });
  console.log("filteredFolders ", filteredFolders)
  filteredFolders.forEach(async folder => {
    //console.log("folder in deviceFoldersBySerial ", folder)
    //debugger

    if(folder.folderID < 1 || folder.status.toLowerCase() === 'deleted' ){
      return
    } else {
      const existingFolder = await this.idb.get('trackedFolders', folder.folderID);
        let folderData;
        if (!existingFolder) {
          console.log('adding new folder... ', folder)
          //this.idb.insert('trackedFolders', folder)
          folderData = await this.downloadJobFolder(folder);
        } else {
          folderData = await this.getFolder(folder.folderID)
        }
        if (folderData) {
          processedFolderData.push(folderData);
        }
      }
    })
  this.jobsDataSubject.next(processedFolderData);
}



// async processFolders(){
//   console.log('processFolders... ', this.deviceFoldersBySerial)
//    // Process folders
//    for (let folder of this.deviceFoldersBySerial) {
//     if (folder.status !== 'DELETED' && folder.folderID >= 0) {
//         const existingFolder = await this.idb.get('trackedFolders', folder.folderID);
//         console.log('processFolders folder ', folder, existingFolder)
//         if (!existingFolder) {
//           console.log('adding new folder... ', folder)
//           //this.idb.insert('trackedFolders', folder)
//             await this.downloadJobFolder(folder);
//         } else {
//           this.getFolder(folder.folderID)
//         }
//     }
//   }
// }

async processFoldersFromDeviceAssets(folders){
   // Process folders
   for (const folder of this.deviceFoldersBySerial) {
    if (folder.status !== 'DELETED' && folder.folderID >= 0) {
        const existingFolder = await this.idb.get('trackedFolders', folder.folderID);
        if (!existingFolder) {
          console.log('adding new folder... ', folder)
          //this.idb.insert('trackedFolders', folder)
            await this.downloadJobFolder(folder);
        } else {
          this.getFolder(folder.folderID)
        }
    }
  }
}

async downloadJobFolder(jobFolder, id?, force?: boolean) {
    console.log("jobFolder => ", jobFolder);
    if (jobFolder.crewFolderID && jobFolder.crewFolderID.includes('https://drive.google.com/drive/folders/')) {
      jobFolder.folderID = jobFolder.crewFolderID.replace('https://drive.google.com/drive/folders/', '');
    }
    if (!jobFolder.files) jobFolder.files = [];
    if (!jobFolder.folderID) jobFolder.folderID = jobFolder?.folder?.id ?? jobFolder?.id;

    let toast = this.toastController.create({
        message: `Syncing`,
        duration: 1500,
        position: 'bottom',
        mode: 'ios',
        translucent: true
    });
    toast.then(toast => toast.present());

    // Download asset folder
    let myfolder = await this.getAssetFolder(jobFolder.folderID, force);
    console.log("myfolder => ", myfolder)
    let folderData = myfolder;
    // jobFolder = {
    //   ...jobFolder,
    //   //name: folderData.name
    // }
    console.log("new jobFolder after folderData => ", jobFolder);

    // Update job folder status
    //jobFolder.status = 'loading ' + myfolder.files.length + ' files';
    jobFolder.status = 'Syncing ';
    await this.idb.insert('trackedFolders', jobFolder);

    // Process files
    for (const file of myfolder.files) {
        if (file.mimeType === 'application/pdf' || file.mimeType === 'text/plain' || file.mimeType === 'image/jpeg' || file.mimeType === 'image/png') {
            const checkIfFileExist = jobFolder.files.some(f => f === file.id);
            console.log('checkIfFileExist', checkIfFileExist)
            if(!checkIfFileExist){
            console.log('adding file', file);
            file.tag = "new";
            jobFolder.files.push(file.id);
            file.tag = 'downloading';
            if(file.base64 === undefined || file.base64 === null || file.base64 === ''){
              console.log('getting remote base64 file data')
              try {
                let remoteFile = await this.getFile(file.id);
                file.base64 = remoteFile.base64;
              } catch (error) {
                console.error("Error downloading file:", error);
                // Handle download error
              }
              // let remoteFile = await this.getFile(file.id);
              // file.base64 = remoteFile.base64;
            }
            file.tag = 'completed';
            console.log('file completed', file);
            file.size = file.size;
            file.tag = 'synced';

            await this.idb.update('files', file.id, file);
            await this.idb.update('trackedFolders', jobFolder.folderID, jobFolder);

            //jobFolder.files.push(file)

            let toast = this.toastController.create({
                message: `Downloaded ${file.name}`,
                duration: 1500,
                position: 'bottom',
                mode: 'ios',
                translucent: true
            });
            toast.then(toast => toast.present());
          } else {
            console.log("file already in the db")
          }
        }
    }

    // Update job folder status to "Synced"
    jobFolder.status = "Synced";
    console.log('updating ' + jobFolder.name + ' job folder status to synced');
    await this.idb.update('trackedFolders', jobFolder.folderID, jobFolder);
    return jobFolder;
}




  // async downloadJobFolder(jobFolder, id, force = true) {
  //   console.log("jobFolder => ", jobFolder)
  //   if (!jobFolder.files) jobFolder.files = [];
  //   if (!jobFolder.folderID) jobFolder.folderID = jobFolder.crewFolderID;
  //   let toast = this.toastController.create({
  //     message: `Syncing`,
  //     duration: 1500,
  //     position: 'bottom',
  //     mode: 'ios',
  //     translucent: true
  //   });
  //   toast.then(toast => toast.present());
  //   let myfolder: SimpleFolder = await this.getAssetFolder(id, force)
  //   console.log("myfolder => ", myfolder)
  //   let myFiles = myfolder.files;
  //   console.log('myFolder', myfolder)
  //   myfolder.status = 'loading ' + myfolder.files.length + ' files';
  //   this.idb.update('trackedFolders', jobFolder.folderID, jobFolder)
  //   jobFolder.files = [];
  //   myFiles.forEach(async file => {
  //     // if(file.mimeType==='application/vnd.google-apps.folder'){
  //     //   await this.downloadFolder(job, file.id,parentName ).then();
  //     // } 
  //     if (file.mimeType === 'application/pdf' || file.mimeType === 'text/plain') {
  //       console.log('adding file', file)

  //       file.tag = "new"
  //       jobFolder.files.push(file);
  //       file.tag = 'downloading';
  //       this.getFile(file.id).then(retval => {

  //         file.tag = 'completed';
  //         console.log('file completed', retval)
  //         file.size = retval.size;
  //         file.tag = 'synced';
  //         file.base64 = retval.base64;
  //         this.idb.update('files', file.id, file)
  //         let toast = this.toastController.create({
  //           message: `Downloaded ${file.name}`,
  //           duration: 1500,
  //           position: 'bottom',
  //           mode: 'ios',
  //           translucent: true
  //         });
  //         toast.then(toast => toast.present());
  //         let remaining = jobFolder.files.filter(rec => rec.tag != 'synced');
  //         if (remaining.length == 0) {
  //           jobFolder.status = "Synced";
  //           console.log('updating ' + jobFolder.name + ' job folder status to synced')
  //           this.idb.update('trackedFolders', jobFolder.folderID, jobFolder)
  //         }
  //       })
  //     }
  //   });

  //   return jobFolder;
  // }

  async getAssetFolder(id, force = false) {
  if(id.toString().length<10) return ;
    return new Promise<any>(async (resolve, reject) => {
      //check to see if this is a tracked folder.

      let localFolder: TrackedFolder = await this.idb.select('trackedFolders', id);
      if (localFolder && !force) {
        console.log('got local folder', localFolder);
        if (localFolder.files.length == 0) {


          console.log('no files in local folder.  loading from server')
          let folder = await this.getRemoteFolder(id, force)
          console.log('loaded from drive', folder);
        }
        resolve(localFolder)
      } else {
        console.log('local folder not found.  loading from drive')
        let folder = await this.getRemoteFolder(id, force)
        resolve(folder);
      }
    })


  }

  async getRemoteFolder(id, force = false) {
    if(id.startsWith('https://drive.google.com/drive/folders/')) {
      id = id.substring('https://drive.google.com/drive/folders/'.length);
    }
    let folder = await this.getFolder(id, true);
    let url = `${this.apiServer}/api/assettrack/${id}/list?sid=${this.sessionID}&forcerefresh=${force}`;
    //   let url = `${this.apiServer}/api/GoogleDrive/Folder/${id}?sid=${this.sessionID}`;
    let directory: SimpleFile[] = await this.http.get<any>(url).pipe(
      map((res: any[]) => {
        console.log('assign paths')
        res.forEach(f => {
          f.path = f.relativePath;
        }
        )
        return res;
      }))
      .toPromise();
    folder.files = directory;
    console.log('getRemoteFolder func loaded from drive', folder);
    return folder;
  }

  async getFolder(id: string, force = false) {

    if(id.startsWith('https://drive.google.com/drive/folders/')) {
      id = id.substring('https://drive.google.com/drive/folders/'.length);
    }
 
    if(id.toString().length<10) return false;
    return new Promise<any>(async (resolve, reject) => {

      //check to see if this is a tracked folder.
      let localFolder: TrackedFolder = await this.idb.select('trackedFolders', id);

      if (localFolder && !force) {
        //console.log('got local folder', localFolder);

        resolve(localFolder)
      } else {
        console.log('local folder not found.  loading from drive with id ' + id)
        let url = `${this.apiServer}/api/GoogleDrive/Folder/${id}?sid=${this.sessionID}`;
        let directory: SimpleFolder = await this.http.get<any>(url).toPromise();
        console.log('loaded from drive');
        resolve(directory);
      }


    })
  }
  async getFolderFiles(id: string) {
    if(id.toString().length<10) return;
    return new Promise<any>(async (resolve, reject) => {

      //check to see if this is a tracked folder.
      let localFolder: TrackedFolder = await this.idb.select('trackedFolders', id);

      if (localFolder) {
        console.log('got local folder', localFolder);

        resolve(localFolder.files)
      } else {
        console.log('local folder not found.  loading from drive')
        let url = `${this.apiServer}/api/GoogleDrive/Folder/${id}?sid=${this.sessionID}`;
        let directory: SimpleFolder = await this.http.get<any>(url).toPromise();
        console.log('loaded from drive');
        resolve(directory);
      }


    })


  }

  async getFile(id: string) {
    return new Promise<SimpleFile>(async (resolve, reject) => {
      let p = await this.idb.get('files', id);
      if (p) {
        resolve(p);
      } else {
        if (!this.ds.online) {
          resolve(null);
        }
        console.log('loading file ' + id + ' from server')
        let simpleFile = await this.downloadFile(id);
        console.log('simpleFile downloaded => ', simpleFile)
        await this.saveFile(simpleFile);
        return resolve(simpleFile);
      }
    });
  }

  async downloadFile(id: string) {
    let url = `${this.apiServer}/api/GoogleDrive/JobFolders/file/${id}?sid=${this.sessionID}`;
    let simpleFile: SimpleFile = await this.http.get<any>(url).toPromise();
    return simpleFile;
  }

  /** saves file object to files datastore indexed by googlefileid */
  //async saveFile(file: SimpleFile): Promise<any> {
  // async saveFile(file: any): Promise<any> {
  //   await this.idb.openDB(this.idb.version);
  //   await this.idb.initializeDB();

  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       var id = `${file.id}`;
  //       //console.log('checking key')
  //       let hasKey = await this.idb.keyExists('files', id);
  //       if (hasKey) {
  //         await this.idb.update('files', id, file);
  //         //console.log('Has Key - Updating File')
  //       } else {
  //         console.log('New Key - Inserting File')
  //         await this.idb.insert('files', file);
  //        // console.log('New Key - Inserting File Success')
  //         //TODO: We need to add new file uploads to the files store and also add them to the files array in the objects from trackedFoldersbuild
  //         if (file.rootFolder) {
  //           console.log('Checking Folder id='+file.rootFolder)
  //           try{

  //             let trackedFolder: SimpleFolder = await this.idb.select('trackedFolders', file.rootFolder)
  //             console.log('Found Folder ',trackedFolder);
  //             if (trackedFolder) {
  //               //file.base64 = '';
  //               trackedFolder.files.push(file);
  //               await this.idb.update('trackedFolders', file.rootFolder, trackedFolder);
  //             }
  //             }catch(e){
  //               console.error('Error updating root folder',e);
  //             }
            
           
  //         }


  //       }
  //       resolve(file);
  //     } catch (e) {
  //       reject(false);
  //     }
  //   });
  // }


  async saveEditFile(file){
    await this.idb.insert('files', file);
    if (file.rootFolderID) {
      console.log('Checking Folder id= '+ file.rootFolderID)
      const upTrack = this.updateTrackedFoldesFiles(file);
      if(upTrack) return true
      else return false
      
    }
  }


  // now we will only save the fileID in the files array of the trackedFolder... 

  updateTrackedFoldesFiles(file){
    return new Promise(async (resolve, reject) => {
      try {
        const id = `${file.id}`;
        console.log('Checking Folder id= '+ file.rootFolderID)
        try{

          let trackedFolder: any = await this.idb.select('trackedFolders', file.rootFolderID)
          console.log('Found TrackedFolder ',trackedFolder);
          if (trackedFolder) {
            trackedFolder.files.push(id);
            await this.idb.update('trackedFolders', file.rootFolderID, trackedFolder);
          }
          }catch(e){
            console.error('Error updating root folder',e);
          }     
        resolve(file);
      } catch (e) {
        reject(false);
      }
    });
  }


  async saveFile(file: any): Promise<any> {

    return new Promise(async (resolve, reject) => {
      try {
        var id = `${file.id}`;
        //console.log('checking key')
        let hasKey = await this.idb.keyExists('files', id);
        if (hasKey) {
          await this.idb.update('files', id, file);
          //console.log('Has Key - Updating File')
        } else {
          console.log('New Key - Inserting File')
          await this.idb.insert('files', file);
          if (file.rootFolderID) {
            console.log('Checking Folder id='+file.rootFolderID)
            try{

              let trackedFolder: SimpleFolder = await this.idb.select('trackedFolders', file.rootFolderID)
              console.log('Found Folder ',trackedFolder);
              if (trackedFolder) {
                //file.base64 = '';
                trackedFolder.files.push(file.id);
                await this.idb.update('trackedFolders', file.rootFolderID, trackedFolder);
              }
              }catch(e){
                console.error('Error updating root folder',e);
              }
            
           
          }


        }
        resolve(file);
      } catch (e) {
        reject(false);
      }
    });
  }

  async uploadFile(file: SimpleFile): Promise<any> {
    file.parents = file.parents || [file.parentFolderID];
    let obj = {
      url: `${this.apiServer}/api/filemanager/folder/${file.parents[0]}/base64?sid=${this.sessionID}`,
      method: 'POST',
      payload: file
    };
    try{
      let file = await this.uQueue.addToQueue(obj);
      return file;
   
    }catch(e){
      console.error('Error adding file upload to queue')
    }
    //return this.saveFile(file);
  }
  // uploadFile(file: SimpleFile): Promise<boolean> {

  //   let f = new BaseFile();
  //   f.name = file.name;
  //   f.id = file.id;
  //   f.parents = file.parents || [file.parentFolderID];
  //   let obj = {
  //     url: `${this.apiServer}/api/filemanager/folder/${f.parents[0]}/base64?sid=${this.sessionID}`,
  //     method: 'POST',
  //     payload: file
  //   };
  //   try{
  //     this.uQueue.addToQueue(obj);
   
  //   }catch(e){
  //     console.error('Error adding file upload to queue')
  //   }
  //   return this.saveFile(file);
  // }

  async updateFile(file){
    await this.idb.update('files', file.id, file).then(res => {
      console.log('file updated => ', res);
      return
    })
  }

  watchFolder(id) {
    
    let cmd = new Command();
    cmd.procedure = 'cmdTrackedDeviceFolderSave';

    cmd.addParameter('GoogleFolderID', id);
    cmd.addParameter('SerialNumber', this.deviceSerial);

    let obj = {
      url: this.apiServer + '/api/cmd?sid=' + this.sessionID,
      method: 'POST',
      payload: cmd
    };

    this.uQueue.addToQueue(obj)
  }
  removeFolderWatch(id) {
    //cmdTrackedDeviceFolderRemove
    let deviceSerial = this.auth.currentDeviceSerial.getValue()
    let cmd = new Command();
    cmd.procedure = 'cmdTrackedDeviceFolderRemove';

    cmd.addParameter('GoogleFolderID', id);
    cmd.addParameter('SerialNumber', deviceSerial);

    let obj = {
      url: this.apiServer + '/api/cmd?sid=' + this.sessionID,
      method: 'POST',
      payload: cmd
    };

    this.uQueue.addToQueue(obj)
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { TimeParserService } from 'src/app/services/time-parser/time-parser.service';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements OnInit {

  @Input() date: string;
  @Input() showUpTime: any;
  @Input() localJob: any;
  @Input() period: any;
  selectorOptionsHs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  selectorOptionsMinutes = ['00', '30'];
  selectorOptionsTime = ["AM", "PM"];

  hoursSelected: any = '--';
  minsSelected: any = '--';
  timeSelected: any = '';
  twoDigits = [];
  addHalf: boolean = false;
  activeHs = null;
  activeMins = null;
  activeTime = null;
  timeConfirmed = '';
  legend24Hs: boolean = false;



  selectedDate = new BehaviorSubject('');
  selectedTime = new BehaviorSubject('');



  constructor(
    private modalController: ModalController,
    private timeParserService: TimeParserService
  ) { }

  ngOnInit() {
    console.log("dateInfo => ", this.date, this.showUpTime, this.localJob, this.period);


    let baseTime = new Date(this.showUpTime);
    let startTime = new Date(this.showUpTime);
    if(this.localJob === undefined){
      console.log("localjob is undefined ");
      this.localJob = {
        startTime: this.showUpTime,
        endTime: null
      };
      //this.localJob.startTime = this.showUpTime
      this.formatDisplayTime(this.showUpTime)
      return
    }
    if ((this.localJob.startTime != undefined || this.localJob.startTime != null)) {
      startTime = new Date(this.localJob.startTime);
    }


    if ( this.period==='startTime') {
   
      this.formatDisplayTime(startTime.toLocaleString())
    }

    if ((this.localJob.endTime != undefined || this.localJob.endTime != null) && this.period==='endTime') {



      baseTime= new Date(this.localJob.endTime);
      let endTime = new Date(this.localJob.endTime);
      var twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
      var timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
      if( timeDiff > twentyFourHoursInMillis){
        this.localJob.is24Hs=true;
        this.legend24Hs=true;
      }
      this.formatDisplayTime(endTime.toLocaleString())
    }

   
  }



  calcTime() {

    this.hoursSelected=(this.hoursSelected==='--' )?'00':this.hoursSelected;
    this.minsSelected=(this.minsSelected==='--' )?'00':this.minsSelected;
    this.timeSelected=(this.timeSelected==='' )?'AM':this.timeSelected;
    
    let startDateTime = new Date(this.localJob.startTime);
    let timeString = this.hoursSelected + ':' + this.minsSelected + ' ' + this.timeSelected;

    let confirmDateTime: string
    if (this.period === 'endTime') {

      // checkdate is the *START DATE* with the selected time.
      let checkDate = new Date(this.timeParserService.parseTimeStringToTimeObject(startDateTime, timeString));
      let checkStart = new Date(startDateTime);

      //if the checkdate is earlier than the start, set it to the following day
      if (checkDate.getTime() <= checkStart.getTime()) {
        checkDate.setDate(checkDate.getDate() + 1);
      }

      //if there was a 24hr add, then add it.
      if (this.localJob.is24Hs) {
        checkDate.setDate(checkDate.getDate() + 1)
      }

      // check to make sure we aren't unnecessarily adding 24hrs
      var timeDiff = Math.abs(checkDate.getTime() - new Date(this.localJob.startTime).getTime());
      // Calculate the number of milliseconds in 24 hours
      var twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
      // Check if the time difference is greater than 24 hours
      if (timeDiff > twentyFourHoursInMillis && !this.localJob.is24Hs) {
        checkDate.setDate(checkDate.getDate() - 1);
      }

      confirmDateTime = this.timeParserService.parseTimeStringToTimeObject(checkDate, timeString);

    } else {
      confirmDateTime = this.timeParserService.parseTimeStringToTimeObject(this.localJob.startTime, timeString);


    }


    this.selectedDate.next(new Date(confirmDateTime).toLocaleDateString())
    this.selectedTime.next(confirmDateTime);
    console.log('selectedDate', this.selectedDate.getValue())
  }







  formatDisplayTime(t) {
    const dateString = new Date(t).toLocaleString("en-US")
    const dateParts = dateString.split(', ');
    const timeString = dateParts[1];
    const [time, period] = timeString.split(' ');
    const [hoursStr, minutesStr] = time.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    console.log('period:', period);
    this.addHours(hours, hours - 1);
    this.addMins(minutes.toString().padStart(2, '0'), minutes == 0 ? 0 : 1);
    let piriodIndex = 0;
    if (period === 'AM') {
      piriodIndex = 0
    } else {
      piriodIndex = 1
    }
    this.setTime(period, piriodIndex);
  }

  addHours(hs, index) {
    //console.log("hours to add => ", hs);
    this.activeHs = index;
    this.hoursSelected = hs.toString();
    this.calcTime();
  }

  addMins(mins, index) {
    //console.log("mins to add => ", mins);
    this.minsSelected = mins.toString();
    this.activeMins = index;
    this.calcTime();
  }

  setTime(time, index) {
    //console.log("time to add => ", time);
    this.timeSelected = time;
    this.activeTime = index;
    this.calcTime();
    return;

  }


  clearHours() {
    this.localJob.startTime = new Date(this.date).toLocaleDateString();
    this.localJob.endTime = new Date(this.date).toLocaleDateString();
    this.addHours(6, -1);
    this.addMins('00', -1);
    this.setTime('AM', -1);
  }

  remove24Hs() {

    this.legend24Hs = false;
    this.localJob.is24Hs = false;
    this.calcTime();
    return;
  }

  add24Hs() {

    this.legend24Hs = true;
    this.localJob.is24Hs = true;
    this.calcTime();
    return;

  }


  confirmTime() {
    let timeString = this.hoursSelected + ':' + this.minsSelected + ' ' + this.timeSelected;

    this.calcTime();  //unnecessary, but just in case

    console.log("timeString => ", timeString, "timeConfirmed => ", this.selectedTime.getValue());
    let timeData = {
      timeConfirmed: this.selectedTime.getValue(),
      is24Hs: this.legend24Hs
    }

    this.modalController.dismiss(timeData);
  }


  updateHours() {
    this.modalController.dismiss(this.hoursSelected);
  }

  closeModal() {
    this.modalController.dismiss(null);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
 
import { HoursCalculatorComponent } from './components/hours-calculator/hours-calculator.component';
import { TimeSelectorComponent } from './components/time-selector/time-selector.component';
import { DatetimeSelectorComponent } from './components/datetime-selector/datetime-selector.component';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
 

@NgModule({
  declarations: [
    AppComponent,
    HoursCalculatorComponent,
    TimeSelectorComponent,
    DatetimeSelectorComponent,
    SplashScreenComponent
  ],
  imports: [CommonModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,  FormsModule, ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
  //enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerImmediately'
})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }],
 
  bootstrap: [AppComponent],
})
export class AppModule {}

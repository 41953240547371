// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorizationClient: '344908434643-9996tvmfca0g87i8n8agqbt0s8kotbhf.apps.googleusercontent.com',
  authorizationState: 'YB272XNkUDotb5sJLM47ac93KHU7wd75BJg', //a random key to ensure logins came from here

  useCmdTimeSheetDefaults : false,
  useCmdTimeSheetCrewDefaults : false,
  useCmdGetSubsistencePerCrewID : false,

  useTimesheetNewApi : true,
  useTimesheetNewUI : false,

  apiServer: 'https://server19.apidas.com',
//  apiServer: 'http://localhost:55609',
  pdfServer: 'https://server19pdf.apidas.com',
  apiKey: 'ByEQZh1Un2LmMuDs2NuMUVuqneZIYPdC5cmQkwa72wmZnlSyuciIUtBhLUDErD8AHNLi7TXeO9TPrUiVyrU2ts5QddzZwzoBXchdnv4ZzmQN98RfWCtmMpqlM3lHavH5TRKhdh2KHCVZCYRmuvZ6VU',

  //apiServer: 'http://localhost:55609',
  // Nacho's authorizationClient:'43691213769-4kltirrpgh06e4cfjj4ahr73d9h4c12l.apps.googleusercontent.com'
  // Powerdash  authorizationClient:'344908434643-gp3vvhpb6nfsqbfmsat9frf6r0i9o7q1.apps.googleusercontent.com'
  //authorizationClient:'344908434643-9996tvmfca0g87i8n8agqbt0s8kotbhf.apps.googleusercontent.com'



};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
